import { useLocation } from 'react-router-dom';

import ErrorComponent from '../components/ErrorComponent';

const ErrorPage = () => {
  const location = useLocation();

  return <ErrorComponent message={location.state?.errorMessage} />;
};

export default ErrorPage;
