import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const useBasicAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location.state?.redirect ?? '/adminbasic';

  const login = (username, password) => {
    axios
      // eslint-disable-next-line no-undef
      .post(`${process.env.REACT_APP_BACKEND_URL}/authenticate/`, {
        params: {
          username,
          password
        }
      })
      .then((res) => {
        if (res.data.authenticated) {
          localStorage.setItem('user', 'authenticated');
          console.log(
            `Successfullu authenticated, Redirecting to ${redirectUrl}`
          );
          navigate(redirectUrl);
          return;
        }
        localStorage.setItem('user', 'unauthenticated');
        navigate('/error', {
          state: { errorMessage: 'Authentication Failed' }
        });
      })
      .catch((error) => {
        localStorage.setItem('user', 'unauthenticated');
        console.log(error);
        navigate('/error', {
          state: { errorMessage: 'Authentication Failed' }
        });
      });
  };

  const logout = () => {
    localStorage.removeItem('user');
  };

  return { login, logout };
};

export default useBasicAuth;
