import { Authenticator } from '@aws-amplify/ui-react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import {
  ProtectedRouteAmplify,
  ProtectedRouteBasic
} from './components/ProtectedRouteComponent';
import AdminPage from './pages/AdminPage';
import ErrorPage from './pages/ErrorPage';
import IntroPage from './pages/IntroPage';
import LeaderboardPage from './pages/LeaderboardPage';
import LoginPage from './pages/LoginPage';
import ThanksPage from './pages/ThanksPage';
import TradingPage from './pages/TradingPage';
import './index.css';
import theme from './theme';

const StyledApp = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const App = () => {
  // eslint-disable-next-line no-undef
  const isNonProduction = process.env.REACT_APP_ENVIRONMENT === 'nonproduction';
  return (
    <ThemeProvider theme={theme}>
      <StyledApp>
        <Authenticator.Provider>
          <Router>
            <Routes>
              <Route index element={<IntroPage />} />
              <Route path="/Trading" element={<TradingPage />} />
              <Route path="/Thanks" element={<ThanksPage />} />
              <Route path="/Leaderboard" element={<LeaderboardPage />} />
              <Route
                path="/Admin"
                element={
                  <ProtectedRouteAmplify>
                    <AdminPage />
                  </ProtectedRouteAmplify>
                }
              />
              {isNonProduction && (
                <Route
                  path="/AdminBasic"
                  element={
                    <ProtectedRouteBasic redirect="/AdminBasic">
                      <AdminPage />
                    </ProtectedRouteBasic>
                  }
                />
              )}
              {isNonProduction && (
                <Route path="/Login" element={<LoginPage />} />
              )}
              <Route path="/Error" element={<ErrorPage />} />
            </Routes>
          </Router>
        </Authenticator.Provider>
      </StyledApp>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
