import styled from 'styled-components';

import optiverGamesLogo from '../img/optiver-logo.png';
import tradingGameTitle from '../img/trading-game-small.png';

const OptiverHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

// eslint-disable-next-line react/prop-types
export const HeaderComponent = () => (
  <OptiverHeader>
    <img
      src={tradingGameTitle}
      style={{ height: '50px', width: 'auto' }}
      alt="THE TRADING GAME"
    />
    <img
      src={optiverGamesLogo}
      style={{ height: '50px', width: 'auto' }}
      alt="Optiver Games Logo"
    />
  </OptiverHeader>
);

export default HeaderComponent;
