/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import axios from 'axios';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import LeaderboardComponent from './LeaderboardComponent';
import {
  chevronDownIcon,
  chevronUpIcon,
  copyIcon,
  editIcon,
  exportIcon,
  saveIcon
} from '../img/icons';
import '@aws-amplify/ui-react/styles.css';

const EditInput = styled.input`
  border: 0px;
  font-size: 16px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-weight: 400;
  width: 100%;
  line-height: 24px;
  color: black;
  background-color: #f6f5f5;
`;

const InputButton = styled.button`
  background-color: #213253;
  height: 50px;
  color: black;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  display: flex;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  margin-left: -2px;
`;

const GameHeading = styled.div`
  font-size: 20px;
  font-weight: 500px;
  color: ${(props) => props.theme.colors.font};
  text-transform: capitalize;
  padding-left: 7px;
  display: flex;
  align-items: center;
`;

const CopyBox = styled.div`
  display: flex;
  height: 48px;
  padding: 8px;
  justify-content: flex-start;
  align-content: center;
  border-radius: 4px;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.font};
  align-items: center;
  font-size: 16px;
`;

const AccordionContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  padding: 16px;
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
`;

const MHeading = styled.div`
  color: ${(props) => props.theme.colors.font};
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const SHeading = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${(props) => props.theme.colors.font};
`;

const ContentSectionColumn = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  margin-top: 24px;
`;

const csvHeaders = [
  { label: 'User ID', key: 'userId' },
  { label: 'User Name', key: 'playerName' },
  { label: 'Result', key: 'result' }
];

const GameAccordionItem = ({
  game,
  users,
  onCopy,
  setIsGameUpdated,
  isGameUpdated,
  navigate
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isActivated, setIsActivated] = useState(!game.archived);
  const [isEditMode, setIsEditMode] = useState(false);
  const [gameRedirectUrl, setGameRedirectUrl] = useState(game.redirectUrl);
  const [gameRedirectUrlValidationError, setGameRedirectUrlValidationError] =
    useState('');

  const leaderboard = users
    ? users.filter((user) => user.gameId === game._id)
    : [];
  const gameLink = `${process.env.REACT_APP_FRONTEND_URL}/Leaderboard?gameId=${game._id}`;

  const updateGame = (changeActivationStatus) => {
    let convertedUrl = '';

    try {
      convertedUrl = new URL(gameRedirectUrl);
    } catch {
      /* empty */
    }
    if (gameRedirectUrl === '' || convertedUrl === '') {
      setGameRedirectUrlValidationError('please enter a valid URL');
    } else {
      setIsEditMode(false);
      setGameRedirectUrlValidationError('');
      axios
        .put(`${process.env.REACT_APP_BACKEND_URL}/updateGame/`, {
          params: {
            gameId: game._id,
            archived: changeActivationStatus ? isActivated : !isActivated,
            redirectUrl: gameRedirectUrl
          }
        })
        .then(() => {
          setIsActivated(changeActivationStatus ? !isActivated : isActivated);
          setIsGameUpdated(!isGameUpdated);
        })
        .catch((error) => {
          console.log(error);
          navigate('/error', {
            state: { errorMessage: 'Unable to update game data' }
          });
        });
    }
  };

  const onDeleteGame = () => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/deleteGame?gameId=${game._id}`)
      .then(() => {
        setIsGameUpdated(!isGameUpdated);
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', {
          state: { errorMessage: 'Unable to delete game' }
        });
      });
  };

  return (
    <ContentSectionColumn style={{ marginTop: 4 }}>
      <AccordionContainer>
        <div
          style={{ display: 'flex' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <img
              src={chevronUpIcon}
              height="25px"
              width="25px"
              style={{ display: 'flex' }}
              alt="expand"
            />
          ) : (
            <img
              src={chevronDownIcon}
              height="25px"
              width="25px"
              style={{ display: 'flex' }}
              alt="collapse"
            />
          )}
          <GameHeading style={{ display: 'flex' }}>{game.gameName}</GameHeading>
        </div>
        {isExpanded && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flex: 0,
              maxWidth: '100%'
            }}
          >
            <div style={{ flex: 1, marginRight: 20 }}>
              <div style={{ display: 'flex' }}>
                <LeaderboardComponent leaderboard={leaderboard} />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: '50%'
              }}
            >
              <MHeading>Controls</MHeading>
              <div
                style={{ display: 'flex', marginBottom: 20, paddingLeft: 8 }}
              >
                <Checkbox
                  toggle
                  checked={!isActivated}
                  onChange={() => updateGame(true)}
                />
                <SHeading style={{ paddingLeft: 8 }}>
                  {isActivated ? 'Deactivate game' : 'Reactivate game'}
                </SHeading>
              </div>

              {isActivated && (
                <>
                  <SHeading style={{ marginTop: 24 }}>
                    Leaderboard page link
                  </SHeading>
                  <CopyBox
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#F6F5F5',
                      color: 'black'
                    }}
                    onClick={(e) => onCopy(e, gameLink)}
                  >
                    <img
                      src={copyIcon}
                      style={{ marginRight: 8 }}
                      alt="copy game link"
                    />
                    <div>{gameLink}</div>
                  </CopyBox>
                </>
              )}
              <SHeading style={{ marginTop: 24 }}>Redirect URL</SHeading>
              <CopyBox
                style={{
                  overflow: 'hidden',
                  backgroundColor: '#F6F5F5',
                  maxWidth: '100%',
                  minHeight: 48,
                  paddingRight: 0,
                  flex: 1,
                  display: 'flex'
                }}
              >
                {isEditMode ? (
                  <>
                    <img
                      src={saveIcon}
                      style={{ marginRight: 8 }}
                      alt="save game redirect url"
                      onClick={() => updateGame()}
                    />
                    <EditInput
                      style={{ padding: 0 }}
                      name="gameRedirectUrlInput"
                      value={gameRedirectUrl}
                      onChange={(e) => {
                        setGameRedirectUrlValidationError('');
                        setGameRedirectUrl(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      overflowX: 'auto',
                      paddingBottom: 5,
                      color: 'black'
                    }}
                  >
                    <img
                      src={editIcon}
                      style={{ marginRight: 8 }}
                      alt="copy game link"
                      onClick={() => setIsEditMode(true)}
                    />
                    <div style={{ textWrap: 'nowrap' }}>{game.redirectUrl}</div>
                  </div>
                )}
              </CopyBox>
              <div style={{ color: 'red', height: 20, marginBottom: -15 }}>
                {gameRedirectUrlValidationError}
              </div>
              {isActivated && (
                <>
                  <SHeading style={{ marginTop: 16 }}>Game ID</SHeading>
                  <CopyBox
                    style={{
                      width: window.innerWidth < 400 ? 245 : '100%'
                    }}
                    onClick={(e) => onCopy(e, game._id)}
                  >
                    <img
                      src={copyIcon}
                      style={{ marginRight: 8, display: 'flex' }}
                      alt="copy game id"
                    />
                    <div>{game._id}</div>
                  </CopyBox>
                </>
              )}
              <SHeading style={{ marginTop: 16 }}>Export to CSV</SHeading>
              <CopyBox style={{ textDecoration: 'underline' }}>
                <img
                  src={exportIcon}
                  style={{ marginRight: 8 }}
                  alt="export-csv"
                />
                <CSVLink
                  data={leaderboard}
                  target="csvFileName"
                  filename={game.gameName}
                  headers={csvHeaders}
                >
                  {game.gameName + '.csv'}{' '}
                </CSVLink>
              </CopyBox>
              {!isActivated && (
                <div style={{ marginTop: 15, marginBottom: 10 }}>
                  <InputButton onClick={onDeleteGame}>Delete Game</InputButton>
                </div>
              )}
            </div>
          </div>
        )}
      </AccordionContainer>
    </ContentSectionColumn>
  );
};

export default GameAccordionItem;
