/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect, useMemo, useState } from 'react';
import GaugeChart from 'react-advanced-gauge-chart';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';

const StockSignalsComponent = ({
  dialvalue0,
  dialvalue1,
  dialvalue2,
  dialvalue3,
  dialvalue4,
  signal0Color,
  signal1Color,
  signal2Color
}) => {
  const size = useWindowSize();
  const [chartSize, setChartSize] = useState([500, 200]);

  useEffect(() => {
    const w = size.width;

    if (w >= 1000) {
      setChartSize([200, 220]);
      return;
    }

    if (w >= 700) {
      setChartSize([150, 150]);
      return;
    }

    setChartSize([100, 100]);
  }, [size.width]);

  const Gauge0 = useMemo(() => {
    return (
      <GaugeChart
        id="gauge-chart0"
        nrOfLevels={5}
        colors={['#FB361D', '#DA6E37', '#EEB94A', '#578799', '#509F5B']}
        arcWidth={0.2}
        percent={(dialvalue3.current + 100) / 200}
        arcPadding={0.01}
        style={{
          height: 'auto',
          width: chartSize[0]
        }}
        needleColor={'#1c3255'}
        hideText={true}
        cornerRadius={0}
      />
    );
  });

  const Gauge1 = useMemo(() => {
    return (
      <GaugeChart
        id="gauge-chart1"
        nrOfLevels={5}
        colors={['#FB361D', '#DA6E37', '#EEB94A', '#578799', '#509F5B']}
        arcWidth={0.2}
        percent={(dialvalue4.current + 100) / 200}
        arcPadding={0.01}
        style={{
          height: 'auto',
          width: chartSize[0]
        }}
        needleColor={'#1c3255'}
        hideText={true}
        cornerRadius={0}
      />
    );
  });
  return (
    <Grid textAlign="center" columns="equal">
      <GridRow columns={2}>
        <GridColumn width={8}>
          <Grid>
            <GridRow columns={3}>
              <GridColumn width={5}>
                <Box sx={{ height: chartSize[1] }}>
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical'
                      },
                      width: 30,
                      color: signal0Color.current,
                      '& .MuiSlider-thumb': {
                        borderRadius: '1px',
                        width: '40px',
                        height: '30px',
                        opacity: 0
                      },
                      '& .MuiSlider-rail': {
                        borderRadius: '37px',
                        color: '#BCC2CC'
                      },
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px'
                    }}
                    orientation="vertical"
                    value={dialvalue0.current}
                    aria-label="Dial1"
                    valueLabelDisplay="auto"
                    max={100}
                    min={-100}
                  />
                </Box>
              </GridColumn>
              <GridColumn width={5}>
                <Box sx={{ height: chartSize[1] }}>
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical'
                      },
                      width: 30,
                      color: signal1Color.current,
                      '& .MuiSlider-thumb': {
                        borderRadius: '7px',
                        width: '40px',
                        height: '30px',
                        opacity: 0
                      },
                      '& .MuiSlider-rail': {
                        borderRadius: '37px',
                        color: '#BCC2CC'
                      },
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px'
                    }}
                    orientation="vertical"
                    value={dialvalue1.current}
                    aria-label="Dial2"
                    valueLabelDisplay="auto"
                    max={100}
                    min={-100}
                  />
                </Box>
              </GridColumn>
              <GridColumn width={5}>
                <Box sx={{ height: chartSize[1] }}>
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical'
                      },
                      width: 30,
                      color: signal2Color.current,
                      '& .MuiSlider-thumb': {
                        borderRadius: '7px',
                        width: '40px',
                        height: '30px',
                        opacity: 0
                      },
                      '& .MuiSlider-rail': {
                        borderRadius: '37px',
                        color: '#BCC2CC'
                      },
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px'
                    }}
                    orientation="vertical"
                    value={dialvalue2.current}
                    aria-label="Dial3"
                    valueLabelDisplay="auto"
                    max={100}
                    min={-100}
                  />
                </Box>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn width={8} stretched>
          {Gauge0}
          {Gauge1}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
export default StockSignalsComponent;
