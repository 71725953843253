import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeaderComponent from './HeaderComponent';

const OptiverGameContainer = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const GameContainerComponent = ({ children }) => (
  <OptiverGameContainer>
    <HeaderComponent />
    {children}
  </OptiverGameContainer>
);

GameContainerComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default GameContainerComponent;
