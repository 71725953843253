/* eslint-disable no-undef */
import { translations, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { useEffect } from 'react';

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      name: process.env.REACT_APP_FEDERATED_IDENTITY_NAME,
      domain: `${process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN}.auth.ap-southeast-2.amazoncognito.com`,
      scope: ['email', 'openid'],
      redirectSignIn: process.env.REACT_APP_FRONTEND_URL + '/admin',
      redirectSignOut: process.env.REACT_APP_FRONTEND_URL + '/admin',
      responseType: 'token'
    }
  }
});

const useAmplifyAuth = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  I18n.putVocabularies(translations);
  I18n.putVocabulariesForLanguage('en', {
    // Loading: 'QR code would show here',
    Code: 'MFA Code',
    Confirm: 'Confirm MFA',
    'Back to Sign In': 'Back to Login',
    'Confirm TOTP Code': 'Please enter the code from your authenticator app',
    'Setup TOTP': 'Scan the below code using an authenticator app to setup MFA'
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {})
      .catch(() => {
        Auth.federatedSignIn({
          provider: process.env.REACT_APP_FEDERATED_IDENTITY_NAME
        });
      });
  });

  return { authStatus };
};

export default useAmplifyAuth;
