/* eslint-disable react/prop-types */
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'semantic-ui-react';
import styled from 'styled-components';

const Score = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

const ScoreValue = styled.div`
  width: 20px;
`;

const TableSection = styled.div`
  color: ${(props) => props.theme.colors.font};
  width: 70%;
  max-height: ${(props) => props.theme.size.minHeight - 100}px;
  overflow-y: auto;
  scrollbar-color: ${(props) => props.theme.colors.backgroundGrey} black;
  padding-right: 50px;

  &&& {
    .ui.table {
      width: 100%;
      background: ${(props) => props.theme.colors.backgroundGrey};
      color: ${(props) => props.theme.colors.font};
      overflow: scroll;
    }

    .ui.table thead th {
      background: ${(props) => props.theme.colors.backgroundGrey};
      color: ${(props) => props.theme.colors.font};
      padding-left: 0;
    }

    .ui.table tbody td {
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      padding-left: 0;
    }

    .ui.table tbody td:last-child {
      text-align: right;
    }

    .ui.table thead th:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
`;

const LeaderboardComponent = ({ leaderboard, playerName }) => {
  leaderboard.sort((a, b) => b['result'] - a['result']);
  const limitedLeaderboard = leaderboard.slice(0, 10);

  return (
    <TableSection>
      <Table singleLine>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>PLAYER</TableHeaderCell>
            <TableHeaderCell>RANKING</TableHeaderCell>
            <TableHeaderCell>SCORE</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {limitedLeaderboard.map((user, index) => (
            <TableRow
              key={index}
              style={{
                color: user.playerName === playerName ? 'yellow' : 'inherit'
              }}
            >
              <TableCell>{user.playerName}</TableCell>
              <TableCell>#{index + 1}</TableCell>
              <TableCell>
                <Score>
                  {user.result > 0 ? (
                    <Icon size="big" color="green" name="caret up" />
                  ) : (
                    <Icon size="big" color="red" name="caret down" />
                  )}
                  <ScoreValue>
                    {user.result > 0 ? `+${user.result}` : `${user.result}`}
                  </ScoreValue>
                </Score>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableSection>
  );
};

export default LeaderboardComponent;
