/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useSound from 'use-sound';

import BuySellComponent from '../components/BuySellComponent';
import GameContainerComponent from '../components/GameContainerComponent';
import NewsAlertComponent from '../components/NewsAlertComponent';
import NewsAlertResultComponent from '../components/NewsAlertResultComponent';
import ProfitLossComponent from '../components/ProfitLossComponent';
import StockSignalsComponent from '../components/StockSignalsComponent';
import StockTradesListComponent from '../components/StockTradesListComponent';
import StockViewerComponent from '../components/StockViewerComponent';
import TradingComponent from '../components/TradingComponent';
import TradingPositionComponent from '../components/TradingPositionComponent';
import TutorialComponent from '../components/TutorialComponent';
import grid from '../img/grid.png';
import buySound from '../sounds/buy.wav';
import downtickSound from '../sounds/downtick.wav';
import sellSound from '../sounds/sell.wav';
import uptickSound from '../sounds/uptick.wav';

let curTime = 0;
let curRound = 0;
const timeTickerInterval = 0.2; // in seconds

const PaddedGrid = styled.div`
  height: 100%;
  display: flex;
  justiy-content: center;
  align-items: center;
  background-image: url(${grid});
  background-size: cover;
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const GridItem = styled.div`
  background-position: center;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TradingPage = () => {
  const durationArray =
    process.env.REACT_APP_ROUND_DURATION.split(',').map(Number);
  const signal0Color = useRef();
  const signal1Color = useRef();
  const signal2Color = useRef();
  const initTrade = useRef(true);
  const justThroughSecond = useRef(false);
  const tempOrders = useRef([]);

  const launch = useRef();
  const [values, setValues] = useState([{ time: 0, stock: 0 }]);
  const dialvalue0 = useRef(0);
  const dialvalue1 = useRef(0);
  const dialvalue2 = useRef(0);
  const dialvalue3 = useRef(0);
  const dialvalue4 = useRef(0);

  const initRecords = [];
  const [records, setRecords] = useState([...initRecords]);
  const [position, setPosition] = useState(0);
  const [orders, setOrders] = useState([]);
  const [profitsHistory, setProfitsHistory] = useState([]);

  const [halted, setHalted] = useState(false);
  const [showEventAlert, setShowEventAlert] = useState(false);
  const [showEventResult, setShowEventResult] = useState(false);
  const [tutorialComplete, setTutorialComplete] = useState(false);

  const [playBuy] = useSound(buySound);
  const [playSell] = useSound(sellSound);
  const [playUp] = useSound(uptickSound);
  const [playDown] = useSound(downtickSound);

  const profitsYAxisLowerBound = useRef(-15);
  const profitsYAxisUpperBound = useRef(15);
  //store last uptick and downtick sound play timeframe according to time
  const lastRing = useRef([{ time: 0, stock: 100 }]);
  //0 for up, 1 for down
  const lastRingType = useRef(0);
  const oscillateFlag = useRef(false);

  const [profitsTrack, setProfitsTrack] = useState([0]);
  const [eventContent, setEventContent] = useState([]);
  const [eventResult, setEventResult] = useState();
  const fetchedResult = useRef();
  const fetchedEvents = useRef();
  const [firstFetch, setFirstFetch] = useState(1);

  let eventFinished = 0;
  let record;
  let recordsForDisplay = records.map((x) => x);

  let navigate = useNavigate();
  const location = useLocation();

  function fixingColor(sliderValue) {
    let colorResult = '';
    let r = 0;
    let g = 0;
    let b = 0;
    let r0 = 0;
    let g0 = 0;
    let b0 = 0;
    if (sliderValue >= -100 && sliderValue <= -60) {
      r0 = 255;
      g0 = 51;
      b0 = 0;
      let percent = ((sliderValue + 100) * 2.5) / 100;
      r = r0 + percent * (218 - r0);
      g = g0 + percent * (110 - g0);
      b = b0 + percent * (55 - b0);
    } else if (sliderValue > -60 && sliderValue <= -20) {
      r0 = 218;
      g0 = 110;
      b0 = 55;
      let percent = ((sliderValue + 60) * 2.5) / 100;
      r = r0 + percent * (238 - r0);
      g = g0 + percent * (185 - g0);
      b = b0 + percent * (74 - b0);
    } else if (sliderValue > -20 && sliderValue <= 20) {
      r0 = 238;
      g0 = 185;
      b0 = 74;
      let percent = ((sliderValue + 20) * 2.5) / 100;
      r = r0 + percent * (87 - r0);
      g = g0 + percent * (135 - g0);
      b = b0 + percent * (153 - b0);
    } else if (sliderValue > 20 && sliderValue <= 100) {
      r0 = 87;
      g0 = 135;
      b0 = 153;
      let percent = ((sliderValue - 20) * 1.25) / 100;
      r = r0 + percent * (80 - r0);
      g = g0 + percent * (159 - g0);
      b = b0 + percent * (91 - b0);
    }

    colorResult += 'rgb(';
    colorResult += r;
    colorResult += ',';
    colorResult += g;
    colorResult += ',';
    colorResult += b;
    colorResult += ')';

    return colorResult;
  }
  useEffect(() => {
    if (
      !(location?.state?.curInstance || location?.state?.curInstance === 0) ||
      !location?.state?.insertedId ||
      !location?.state?.playerName ||
      !location?.state?.gameId
    ) {
      console.log(
        'unable to get location.state variables',
        location?.state?.curInstance,
        location?.state?.insertedId,
        location?.state?.playerName,
        location?.state?.gameId
      );
      navigate('/');
    } else {
      if (firstFetch === 1) {
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/allShares/`, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0'
            },
            params: { curInstance: location.state.curInstance }
          })
          .then((res) => {
            fetchedResult.current = res.data.sort((a, b) =>
              parseFloat(a.Time) > parseFloat(b.Time) ? 1 : -1
            );
          })
          .catch({});
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/tradeevents/`, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0'
            },
            params: { curInstance: location.state.curInstance }
          })
          .then((res) => {
            fetchedEvents.current = res.data.sort((a, b) =>
              parseInt(a.round) > parseInt(b.round) ? 1 : -1
            );
          })
          .catch({});

        setFirstFetch(0);
      }
    }
  }, [firstFetch, location, navigate]);

  useEffect(() => {
    if (showEventAlert) {
      //open the event modal,load event contents and set eventContent and eventResult
      if (fetchedEvents.current[0]) {
        setEventContent(fetchedEvents.current[0].alert);
        setEventResult(fetchedEvents.current[0].outcome);
      }
      fetchedEvents.current.shift();
    }
  }, [showEventAlert]);

  const routeChange = () => {
    let path = '/Thanks';
    navigate(path, {
      state: {
        insertedId: location.state.insertedId,
        playerName: location.state.playerName,
        score: profitsTrack[profitsTrack.length - 1].gain,
        gameId: location.state.gameId
      }
    });
  };

  const gameLength = parseInt(process.env.REACT_APP_GAME_LENGTH, 10);

  useEffect(() => {
    if (!tutorialComplete) {
      return;
    }

    if (!halted && curTime < gameLength) {
      setShowEventAlert(false);
      if (!launch.current) {
        clearInterval(launch.current);
      }
      launch.current = setInterval(() => ReadData(), timeTickerInterval * 1000);
      return;
    }

    if (halted && curTime < gameLength) {
      setShowEventAlert(true);
      clearInterval(launch.current);
      launch.current = null;
      return;
    }

    if (curTime >= gameLength) {
      console.log('game finished');
      routeChange();
      clearInterval(launch.current);
      curTime = 0;
      curRound = 0;
      return;
    }
  }, [halted, tutorialComplete]);

  useEffect(() => {
    if (values.length > 250 && values.length < 350) {
      values.shift();
    } else if (values.length >= 350 && values.length < 450) {
      values.splice(0, 100);
    } else if (values.length >= 550) {
      values.splice(0, 200);
    }

    //play up tick sound or down tick sound
    if (values.length > 0) {
      //update oscillate flag first
      if (oscillateFlag.current === false) {
        if (
          Math.abs(
            parseInt(values[parseInt(values.length) - 1].stock) -
              parseInt(lastRing.current[0].stock)
          ) >= 5
        ) {
          oscillateFlag.current = true;
        }
      }
      if (
        parseInt(values[parseInt(values.length) - 1].stock) % 10 === 0 ||
        Math.abs(
          parseInt(values[parseInt(values.length) - 1].stock) -
            parseInt(lastRing.current[0].stock)
        ) >= 10
      ) {
        if (
          parseInt(values[parseInt(values.length) - 1].stock) >
          parseInt(lastRing.current[0].stock)
        ) {
          playUp();
          lastRing.current.pop();
          lastRing.current.push(values[values.length - 1]);
          lastRingType.current = 0;
          oscillateFlag.current = false;
        } else if (
          parseInt(values[parseInt(values.length) - 1].stock) <
          parseInt(lastRing.current[0].stock)
        ) {
          playDown();
          lastRing.current.pop();
          lastRing.current.push(values[values.length - 1]);
          lastRingType.current = 1;
          oscillateFlag.current = false;
        } else {
          //consider oscillate and play sound accordingly
          if (oscillateFlag.current) {
            if (lastRingType.current === 0) {
              playDown();
              oscillateFlag.current = false;
            } else if (lastRingType.current === 1) {
              playUp();
              oscillateFlag.current = false;
            }
          }
        }
      }
    }
  }, [values]);

  useEffect(() => {
    updateProfits();
  }, [position]);

  useEffect(() => {
    while (tempOrders.current.length !== 0) {
      orders.push(tempOrders.current.pop());
    }
  }, [profitsHistory]);

  useEffect(() => {
    updateProfitsTrack();
  }, [values]);

  useEffect(() => {
    if (profitsTrack.length > 300) {
      profitsTrack.shift();
    }
  }, [profitsTrack]);

  const updateProfits = () => {
    if (orders.length !== 0 && justThroughSecond.current === true) {
      if (tempOrders.current.length !== 0 && position === 0) {
        justThroughSecond.current = false;
        let result = 0;
        let buySum = 0;
        let sellSum = 0;
        tempOrders.current.forEach((o) => {
          orders.push(o);
        });
        tempOrders.current = [];
        orders.forEach((o) => {
          if (o.order === 'Buy') {
            buySum += parseInt(o.orderAddress);
          } else if (o.order === 'Sell') {
            sellSum += parseInt(o.orderAddress);
          }
        });
        result = sellSum - buySum;
        if (profitsHistory.length === 0) {
          setOrders([]);
          setProfitsHistory({ gain: result });
        } else {
          setOrders([]);
          setProfitsHistory({ gain: profitsHistory.gain + result });
        }
      } else if (tempOrders.current.length !== 0 && position !== 0) {
        let result = 0;
        let buySum = 0;
        let sellSum = 0;
        let buyOrderCount = 0;
        let sellOrderCount = 0;
        let initBuyOrderCount = 0;
        let initSellOrderCount = 0;
        orders.forEach((o) => {
          if (o.order === 'Buy') {
            buyOrderCount++;
            initBuyOrderCount++;
          } else if (o.order === 'Sell') {
            sellOrderCount++;
            initSellOrderCount++;
          }
        });
        tempOrders.current.forEach((o) => {
          if (o.order === 'Buy') {
            buyOrderCount++;
          } else if (o.order === 'Sell') {
            sellOrderCount++;
          }
        });
        if (buyOrderCount !== sellOrderCount) {
          if (buyOrderCount > sellOrderCount) {
            if (initBuyOrderCount < initSellOrderCount) {
              //when pass over 0 after event
              for (let i = 0; i < initSellOrderCount - initBuyOrderCount; i++) {
                orders.push(tempOrders.current.pop());
              }
              orders.forEach((o) => {
                if (o.order === 'Buy') {
                  buySum += parseInt(o.orderAddress);
                } else if (o.order === 'Sell') {
                  sellSum += parseInt(o.orderAddress);
                }
              });
              result = sellSum - buySum;
              if (profitsHistory.length === 0) {
                setOrders([]);
                setProfitsHistory({ gain: result });
              } else {
                setOrders([]);
                setProfitsHistory({ gain: profitsHistory.gain + result });
              }
            } else {
              if (sellOrderCount - initSellOrderCount > 0) {
                for (let i = 0; i < sellOrderCount - initSellOrderCount; i++) {
                  orders.push(tempOrders.current.pop());
                }
              } else if (buyOrderCount - initBuyOrderCount > 0) {
                for (let i = 0; i < buyOrderCount - initBuyOrderCount; i++) {
                  orders.push(tempOrders.current.pop());
                }
              }
            }
          } else {
            if (initSellOrderCount < initBuyOrderCount) {
              //when pass over 0 after event
              for (let i = 0; i < initBuyOrderCount - initSellOrderCount; i++) {
                orders.push(tempOrders.current.pop());
              }

              orders.forEach((o) => {
                if (o.order === 'Buy') {
                  buySum += parseInt(o.orderAddress);
                } else if (o.order === 'Sell') {
                  sellSum += parseInt(o.orderAddress);
                }
              });
              result = sellSum - buySum;
              if (profitsHistory.length === 0) {
                setOrders([]);
                setProfitsHistory({ gain: result });
              } else {
                setOrders([]);
                setProfitsHistory({ gain: profitsHistory.gain + result });
              }
            } else {
              if (sellOrderCount - initSellOrderCount > 0) {
                for (let i = 0; i < sellOrderCount - initSellOrderCount; i++) {
                  orders.push(tempOrders.current.pop());
                }
              } else if (buyOrderCount - initBuyOrderCount > 0) {
                for (let i = 0; i < buyOrderCount - initBuyOrderCount; i++) {
                  orders.push(tempOrders.current.pop());
                }
              }
            }
          }
        }

        justThroughSecond.current = false;
      }
    } else if (orders.length === 0 && justThroughSecond.current === true) {
      while (tempOrders.current.length !== 0) {
        orders.push(tempOrders.current.pop());
      }
    } else if (orders.length !== 0 && justThroughSecond.current === false) {
      if (position === 0) {
        let result = 0;
        let buySum = 0;
        let sellSum = 0;
        orders.forEach((o) => {
          if (o.order === 'Buy') {
            buySum += parseInt(o.orderAddress);
          } else if (o.order === 'Sell') {
            sellSum += parseInt(o.orderAddress);
          }
        });
        result = sellSum - buySum;
        if (profitsHistory.length === 0) {
          setOrders([]);
          setProfitsHistory({ gain: result });
        } else {
          setOrders([]);
          setProfitsHistory({ gain: profitsHistory.gain + result });
        }
      }
    }
  };

  const updateProfitsTrack = () => {
    let potentialGain = 0;
    //calculate potential gain
    let buyCount = 0;
    let sellCount = 0;
    let buySum = 0;
    let sellSum = 0;
    orders.forEach((o) => {
      if (o.order === 'Buy') {
        buyCount += 1;
        buySum += parseInt(o.orderAddress);
      } else if (o.order === 'Sell') {
        sellCount += 1;
        sellSum += parseInt(o.orderAddress);
      }
    });

    if (buyCount > sellCount) {
      potentialGain = Math.round(
        sellSum -
          buySum +
          values[values.length - 1].stock * (buyCount - sellCount)
      );
    } else if (sellCount > buyCount) {
      potentialGain = Math.round(
        sellSum -
          buySum -
          values[values.length - 1].stock * (sellCount - buyCount)
      );
    }

    if (profitsHistory.length === 0) {
      if (profitsTrack.length > 300) {
        profitsTrack.shift();
      }
      setProfitsTrack(profitsTrack.concat({ gain: potentialGain }));
      profitsYAxisLowerBound.current = -15 + potentialGain;
      profitsYAxisUpperBound.current = 15 + potentialGain;
    } else {
      if (profitsTrack.length > 300) {
        profitsTrack.shift();
      }
      profitsYAxisLowerBound.current =
        parseInt(profitsHistory.gain + potentialGain) - 15;
      profitsYAxisUpperBound.current =
        parseInt(profitsHistory.gain + potentialGain) + 15;
      setProfitsTrack(
        profitsTrack.concat({
          gain: parseInt(profitsHistory.gain) + potentialGain
        })
      );
    }
  };

  const ReadData = () => {
    if (curTime < gameLength) {
      const roundDuration = durationArray[curRound];
      if (initTrade.current === true) {
        if (
          roundDuration &&
          curTime < roundDuration &&
          curRound === 0 &&
          eventFinished === 0
        ) {
          //load first 40 seconds data
          if (fetchedResult.current === undefined && !tutorialComplete) {
            /* empty */
          } else if (fetchedResult.current !== undefined && tutorialComplete) {
            dialvalue0.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial1
            );
            signal0Color.current = fixingColor(dialvalue0.current);
            dialvalue1.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial2
            );
            signal1Color.current = fixingColor(dialvalue1.current);
            dialvalue2.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial3
            );
            signal2Color.current = fixingColor(dialvalue2.current);
            dialvalue3.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial4
            );
            dialvalue4.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial5
            );
            setValues((values) =>
              values.concat({
                time: fetchedResult.current[parseInt(curTime * 50)].Time,
                stock: fetchedResult.current[parseInt(curTime * 50)].Stock
              })
            );

            curTime = parseFloat(curTime) + timeTickerInterval;
            curTime = curTime.toFixed(2);
          }
        } else {
          initTrade.current = false;
          setHalted(true);
        }
      } else {
        if (curTime < (roundDuration ?? gameLength) && eventFinished === 0) {
          if (fetchedResult.current === undefined) {
            /* empty */
          } else {
            dialvalue0.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial1
            );
            signal0Color.current = fixingColor(dialvalue0.current);
            dialvalue1.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial2
            );
            signal1Color.current = fixingColor(dialvalue1.current);
            dialvalue2.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial3
            );
            signal2Color.current = fixingColor(dialvalue2.current);
            dialvalue3.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial4
            );
            dialvalue4.current = parseInt(
              fetchedResult.current[parseInt(curTime * 50)].Dial5
            );

            setValues((values) =>
              values.concat({
                time: fetchedResult.current[parseInt(curTime * 50)].Time,
                stock: fetchedResult.current[parseInt(curTime * 50)].Stock
              })
            );

            curTime = parseFloat(curTime) + timeTickerInterval;
            curTime = curTime.toFixed(2);
          }
        } else {
          //whatever doing, freeze the render first!
          setHalted(true);
          console.log('Trading is halted');
          if (eventFinished === 1) {
            console.log(
              "event has been done and now maybe it's loading 0.5 seconds data"
            );
          }
        }
      }
    } else {
      //means we have reached the end of game
      setHalted(true);
      console.log('Trading is halted');
      console.log(
        'trying to read data that is beyond the limit of game length'
      );
    }
  };

  const incrementCurRound = () => {
    //set time limits for triggering read half second data
    eventFinished = 1;
    if (curTime < gameLength) {
      curRound += 1;
      orderPlaced.current = false;
    }
    setHalted(false);
  };

  const addRecord = (theRecord) => {
    if (records.length === 4) {
      records.shift();
      records.push(theRecord);
      setRecords(records);
    } else if (records.length >= 0 && records.length < 4) {
      records.push(theRecord);
      setRecords(records);
    } else {
      console.log('error occurs when placing orders');
    }
  };

  const placeBuy = () => {
    if (position < 5 && fetchedResult.current !== undefined) {
      justThroughSecond.current = false;
      record = {
        order: 'Buy',
        orderAddress: parseInt(values[values.length - 1].stock)
      };
      addRecord(record);
      orders.push(record);
      setPosition(position + 1);
      playBuy();
    }
  };
  const placeSell = () => {
    if (position > -5 && fetchedResult.current !== undefined) {
      justThroughSecond.current = false;
      record = {
        order: 'Sell',
        orderAddress: parseInt(values[values.length - 1].stock)
      };
      addRecord(record);
      orders.push(record);
      setPosition(position - 1);
      playSell();
    }
  };

  const changePositionsFromModal = (selectedPosition) => {
    if (selectedPosition === null || selectedPosition === undefined) {
      return;
    }

    if (orderPlaced.current === false) {
      if (position < parseInt(selectedPosition)) {
        for (let i = position; i < parseInt(selectedPosition); i++) {
          if (i < 5) {
            record = {
              order: 'Buy',
              orderAddress: parseInt(values[values.length - 1].stock)
            };
            addRecord(record);
            tempOrders.current.push(record);
          }
        }
        justThroughSecond.current = true;
        setPosition(parseInt(selectedPosition));
      } else if (position > parseInt(selectedPosition)) {
        for (let i = position; i > parseInt(selectedPosition); i--) {
          if (i > -5) {
            record = {
              order: 'Sell',
              orderAddress: parseInt(values[values.length - 1].stock)
            };
            addRecord(record);
            tempOrders.current.push(record);
          }
        }
        justThroughSecond.current = true;
        setPosition(parseInt(selectedPosition));
      }
      orderPlaced.current = true;
    }
  };

  const orderPlaced = useRef(false);

  if (!tutorialComplete) {
    return (
      <TutorialComponent
        setTutorialComplete={() => setTutorialComplete(true)}
      />
    );
  }

  return (
    <>
      <GameContainerComponent>
        <PaddedGrid>
          <GridContainer>
            <Row>
              <GridItem>
                <TradingComponent title="STOCK VIEWER">
                  <StockViewerComponent values={values} />
                </TradingComponent>
              </GridItem>
              <GridItem>
                <TradingComponent title="TRADES">
                  <StockTradesListComponent
                    recordsForDisplay={recordsForDisplay}
                  />
                </TradingComponent>
              </GridItem>
            </Row>
            <Row>
              <GridItem>
                <TradingComponent title="STOCK SIGNALS">
                  <StockSignalsComponent
                    dialvalue0={dialvalue0}
                    dialvalue1={dialvalue1}
                    dialvalue2={dialvalue2}
                    dialvalue3={dialvalue3}
                    dialvalue4={dialvalue4}
                    signal0Color={signal0Color}
                    signal1Color={signal1Color}
                    signal2Color={signal2Color}
                  />
                </TradingComponent>
              </GridItem>
              <GridItem>
                <TradingComponent title="PROFIT/LOSS">
                  <ProfitLossComponent
                    profitsTrack={profitsTrack}
                    profitsYAxisLowerBound={profitsYAxisLowerBound}
                    profitsYAxisUpperBound={profitsYAxisUpperBound}
                  />
                </TradingComponent>
              </GridItem>
            </Row>
            <Row>
              <GridItem>
                <BuySellComponent placeBuy={placeBuy} placeSell={placeSell} />
              </GridItem>
              <GridItem>
                <TradingPositionComponent position={position} />
              </GridItem>
            </Row>
          </GridContainer>
        </PaddedGrid>
      </GameContainerComponent>
      {showEventAlert && eventContent && (
        <NewsAlertComponent
          setShowEventAlert={setShowEventAlert}
          setShowEventResult={setShowEventResult}
          eventContent={eventContent}
          changePositionsFromModal={changePositionsFromModal}
          curRound={curRound}
        />
      )}
      {showEventResult && (
        <NewsAlertResultComponent
          eventResult={eventResult}
          setShowEventResult={setShowEventResult}
          incrementCurRound={incrementCurRound}
        />
      )}
    </>
  );
};
export default TradingPage;
