/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GameContainerComponent from './GameContainerComponent';

const XlHeading = styled.div`
  font-size: 28px;
`;

const AdminContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 100px 50px 100px;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  color: ${(props) => props.theme.colors.font};
`;

// eslint-disable-next-line react/prop-types
const AdminContainerComponent = ({ children, pageHeader }) => {
  return (
    <GameContainerComponent>
      <AdminContentContainer>
        <XlHeading>{pageHeader}</XlHeading>
        {children}
      </AdminContentContainer>
    </GameContainerComponent>
  );
};

AdminContainerComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default AdminContainerComponent;
