import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { pageErrorIcon } from './../img/icons';
import GameContainerComponent from './GameContainerComponent';

export const ErrorHeading = styled.div`
  font-size: 28px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.font};
  margin-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.font};
  margin-left: 8px;
`;

export const ErrorInnerContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 40px;
  flex: 1;
`;

const Column = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

// eslint-disable-next-line react/prop-types
const ErrorComponent = ({ message }) => {
  const errorMsg = `${
    message ?? 'Unfortunately something went wrong with this query'
  }. Please contact your administrator.`;

  return (
    <GameContainerComponent>
      <Column>
        <ErrorHeading>
          Sorry, we couldn&apos;t find what you were looking for
        </ErrorHeading>
        <img src={pageErrorIcon} alt="" />
        <ErrorInnerContainer>
          <Icon large name="warning sign" color="orange" />
          <ErrorText>{errorMsg}</ErrorText>
        </ErrorInnerContainer>
      </Column>
    </GameContainerComponent>
  );
};

export default ErrorComponent;
