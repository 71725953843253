/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { SecondaryButton } from '../components/ButtonComponents';
import GameContainerComponent from '../components/GameContainerComponent';
import LeaderboardComponent from '../components/LeaderboardComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding: 50px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.font};
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

const LeaderboardPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [gameId] = useState(searchParams.get('gameId'));
  const [game, setGame] = useState({});
  const [userName] = useState(searchParams.get('userName'));
  const [leaderboard, setLeaderboard] = useState([]);
  const navigate = useNavigate();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/getLeaderboard?gameId=${gameId}`
      )
      .then((res) => {
        setLeaderboard(res.data);
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', {
          state: { errorMessage: 'Unable to retrieve leaderboard' }
        });
      });

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getGameById?gameId=${gameId}`)
      .then((res) => {
        if (res.data.archived) {
          navigate('/error', {
            state: { errorMessage: 'The provided game ID has been archived' }
          });
          return;
        }
        setGame(res.data);
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', {
          state: { errorMessage: 'Unable to retrieve game data' }
        });
      });

    setSearchParams('');
  }, [setLeaderboard, gameId, setSearchParams, navigate, time]);

  return (
    <GameContainerComponent>
      <Container>
        <TitleSection>
          <Title>LEADERBOARD</Title>
          {game && (
            <SecondaryButton
              content="CLOSE"
              buttonAction={() => (window.location.href = game.redirectUrl)}
            />
          )}
        </TitleSection>
        {game && (
          <LeaderboardComponent
            leaderboard={leaderboard}
            playerName={userName}
          />
        )}
      </Container>
    </GameContainerComponent>
  );
};

export default LeaderboardPage;
