/* eslint-disable react/prop-types */
import { Container, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

import { BuyButton, SellButton } from './ButtonComponents';

const BackgroundContainer = styled(Container)`
  padding-right: 10px;
  width: 500px !important;

  @media (max-width: 1200px) {
    width: 400px !important;
  }

  @media (max-width: 1000px) {
    width: 350px !important;
  }

  @media (max-width: 700px) {
    width: 100px !important;
  }
`;

const BuySellComponent = ({ placeBuy, placeSell }) => {
  return (
    <BackgroundContainer>
      <Grid textAlign="center">
        <GridRow verticalAlign="middle">
          <GridColumn width={6}>
            <BuyButton fluid={true} action={placeBuy} />
          </GridColumn>
          <GridColumn width={6}>
            <SellButton fluid={true} action={placeSell} />
          </GridColumn>
        </GridRow>
      </Grid>
    </BackgroundContainer>
  );
};
export default BuySellComponent;
