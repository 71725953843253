const theme = {
  colors: {
    primary: '#0064ff',
    secondary: '#00f3ab',
    backgroundGrey: '#141823',
    backgroundDark: '#000000',
    dismiss: '#141823',
    alert: '#FF0000',
    sell: '#FF0000',
    buy: '#00B152',
    loss: '#FF0000',
    profit: '#00B152',
    par: '#eaf4fc',
    font: '#eaf4fc',
    border: '#448a9d'
  },
  size: {
    minHeight: 775,
    minWidth: 1154
  }
};

export default theme;
