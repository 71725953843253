import styled from 'styled-components';

export const ImageOverlay = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  @media (max-width: 1200px) {
    width: 90%;
    padding: 30px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 50px;
  }
`;

export default ImageOverlay;
