/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { useEffect, useState } from 'react';
import { Dropdown, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

import AlertModalComponent from './AlertModalComponent';
import { DismissButton, SecondaryButton } from './ButtonComponents';

const PtraTimer = styled.div`
  background-color: ${(props) => props.theme.colors.alert};
  color: ${(props) => props.theme.colors.font};
  font-weight: 900;
  text-align: center;
  border: 2px solid red;
  border-radius: 10px;
  padding: 5px 30px 5px 30px;
  margin-right: 30px;
`;

const EventContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20em;
`;

const EventSpan = styled.span`
  font-size: 22px;
  margin-left: 50px;
  margin-right: 50px;
  color: ${(props) => props.theme.colors.font};
  padding-bottom: 10px;
`;

const NewsAlertComponent = ({
  eventContent,
  setShowEventResult,
  setShowEventAlert,
  changePositionsFromModal,
  curRound
}) => {
  const positionOptions = [
    { key: '-', text: '-', value: '-' },
    { key: 5, text: '5', value: 5 },
    { key: 4, text: '4', value: 4 },
    { key: 3, text: '3', value: 3 },
    { key: 2, text: '2', value: 2 },
    { key: 1, text: '1', value: 1 },
    { key: 0, text: '0', value: 0 },
    { key: -1, text: '-1', value: -1 },
    { key: -2, text: '-2', value: -2 },
    { key: -3, text: '-3', value: -3 },
    { key: -4, text: '-4', value: -4 },
    { key: -5, text: '-5', value: -5 }
  ];

  const [confirmed, setConfirmed] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(
    positionOptions[0].value
  );

  const eventDuration = parseInt(process.env.REACT_APP_EVENT_DURATION, 10);
  const [timePassed, setTimePassed] = useState(
    [2, 3, 4].includes(curRound)
      ? eventDuration + eventDuration / 2
      : eventDuration
  );

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setTimePassed((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          }

          return prevTime;
        }),
      1000
    );
    return () => {
      clearInterval(intervalId); // Clear the interval to avoid memory leaks
    };
  }, []);

  useEffect(() => {
    if (timePassed <= 0) {
      setShowEventResult(true);
      setShowEventAlert(false);
    }
  }, [timePassed]);

  const timer = (
    <PtraTimer>
      00:
      {timePassed >= 10 ? timePassed : '0' + timePassed}
    </PtraTimer>
  );

  const content = (
    <Grid style={{ padding: 0 }}>
      <GridRow>
        <GridColumn>
          <EventContents>
            <EventSpan>{getEventContents(eventContent)}</EventSpan>
            <br></br>
            <EventSpan>What position do you want?</EventSpan>
          </EventContents>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn
          align="center"
          verticalAlign="middle"
          style={{
            verticalAlign: 'middle',
            fontSize: '20px',
            paddingBottom: '5px'
          }}
        >
          Enter an integer from -5 to 5
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn align="center" verticalAlign="middle">
          <Dropdown
            size="small"
            options={positionOptions}
            onChange={(_event, data) => setSelectedPosition(data.value)}
            value={selectedPosition}
            defaultValue={positionOptions[0].value}
            selection
            upward
            disabled={confirmed}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );

  const actions = [
    <DismissButton
      content={!confirmed ? 'DISMISS' : `PROCEED`}
      buttonAction={() => setTimePassed(0)}
      key="dismiss"
    />,
    <SecondaryButton
      content={
        !confirmed ? 'SUBMIT' : `Trading starting in ${timePassed} seconds`
      }
      disabled={confirmed}
      buttonAction={() => {
        changePositionsFromModal(
          selectedPosition == positionOptions[0].value
            ? null
            : Number(selectedPosition)
        );
        setConfirmed(true);
      }}
      key="submit"
    />
  ];

  return (
    <AlertModalComponent
      alertTitle="NEWS ALERT"
      timer={timer}
      content={content}
      actions={actions}
    />
  );
};

const config = {
  tex2jax: {
    inlineMath: [['$', '$']],
    displayMath: [['$$', '$$']]
  }
};

const getEventContents = (eventContent) => {
  const resultArray = [];
  for (let i = 0; i < eventContent.length; i++) {
    if (eventContent[i].includes('myequation0')) {
      const [partA, partB] = eventContent[i].split('myequation0');
      resultArray.push(
        <span key={i}>
          {partA}
          <MathJaxContext config={config} version={3} inline="true">
            <MathJax inline="true">{'\\(e^{\\Pi} > \\Pi^{e} \\)'}</MathJax>
          </MathJaxContext>
          {partB}
        </span>
      );
    } else if (eventContent[i].includes('myequation1')) {
      resultArray.push(
        <span style={{ textAlign: 'center' }} key={i}>
          <MathJaxContext
            config={config}
            version={3}
            style={{ display: 'inline', textAlign: 'center' }}
          >
            <MathJax style={{ textAlign: 'center ' }}>
              {
                '\\(\\frac{1}{2} \\times \\frac{3}{4} \\times \\frac{5}{6} \\times \\frac{7}{8} \\times ... \\frac{99}{100} \\)'
              }
            </MathJax>
          </MathJaxContext>
        </span>
      );
    } else if (eventContent[i].includes('myequation2')) {
      resultArray.push(
        <span style={{ textAlign: 'center' }} key={i}>
          <MathJaxContext
            config={config}
            version={3}
            style={{ display: 'inline', textAlign: 'center' }}
          >
            <MathJax style={{ textAlign: 'center ' }}>
              {'\\(3^{100} \\times 2^{80} \\)'}
            </MathJax>
          </MathJaxContext>
        </span>
      );
    } else {
      resultArray.push(<span key={i}>{eventContent[i]}</span>);
      resultArray.push(<br key={`${i}-br`} />);
    }
  }

  return resultArray;
};

export default NewsAlertComponent;
