/* eslint-disable react/prop-types */
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledPrimaryButton = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.colors.secondary};
    color: #141923;
    padding: 10px 40px 10px 40px;
    border-radius: 15px;
    font-weight: 400;
    z-index: 1;
  }
`;

const StyledSecondaryButton = styled(Button)`
  &&& {
    border: 1px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    color: ${(props) => props.theme.colors.font};
    font-size: 12px;
    font-weight: 500;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
    min-width: 120px;
  }
`;

const StyledDismissButton = styled(Button)`
  &&& {
    font-size: 12px;
    font-weight: 500;
    border-radius: 0.5em;
    padding: 10px 30px 10px 30px;
    border: 1px solid #1c3255;
  }
`;

const StyledBuyButton = styled(Button)`
  &&& {
    color: white;
    background-color: ${(props) => props.theme.colors.buy};
    font-weight: 500;
    border-radius: 0.5em;
  }
`;

const StyledSellButton = styled(Button)`
  &&& {
    color: white;
    background-color: ${(props) => props.theme.colors.sell};
    font-weight: 500;
    border-radius: 0.5em;
  }
`;

const PrimaryButton = ({ content, buttonAction, size = 'large' }) => {
  return (
    <StyledPrimaryButton
      primary
      size={size}
      content={content}
      onClick={buttonAction}
    />
  );
};

const SecondaryButton = ({
  content,
  buttonAction,
  size = 'large',
  disabled
}) => {
  return (
    <StyledSecondaryButton
      primary
      disabled={disabled}
      size={size}
      content={content}
      onClick={buttonAction}
    />
  );
};

const DismissButton = ({ content, buttonAction, size = 'large', disabled }) => {
  return (
    <StyledDismissButton
      secondary
      disabled={disabled}
      size={size}
      onClick={buttonAction}
      icon
      labelPosition="left"
    >
      <Icon name="delete" />
      {content}
    </StyledDismissButton>
  );
};

const BuyButton = ({ action, fluid, size = 'large' }) => {
  return (
    <StyledBuyButton fluid={fluid} size={size} content="BUY" onClick={action} />
  );
};

const SellButton = ({ action, fluid, size = 'large' }) => {
  return (
    <StyledSellButton
      fluid={fluid}
      size={size}
      content="SELL"
      onClick={action}
    />
  );
};

export { PrimaryButton, SecondaryButton, DismissButton, BuyButton, SellButton };
