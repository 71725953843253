/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SecondaryButton } from '../components/ButtonComponents';
import GameContainerComponent from '../components/GameContainerComponent';
import ImageOverlay from '../components/ImageOverlay';
import marketClosed from '../img/market-closed.png';

const MessagesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 250px;
`;

const Message = styled.div`
  color: ${(props) => props.theme.colors.font};
  font-size: 14px;
  text-align: center;
`;

const Score = styled.span`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  border-radius: 20px;
  padding: 5px 50px 5px 50px;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.font};
`;

const Actions = styled.div`
  padding-top: 100px;
`;

const ThanksPage = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    if (
      !location?.state?.insertedId ||
      !location?.state?.playerName ||
      !location?.state?.gameId ||
      !(location?.state?.score || location?.state?.score === 0)
    ) {
      navigate('/error', { state: { errorMessage: 'Invalid data provided' } });
    } else {
      axios
        .put(`${process.env.REACT_APP_BACKEND_URL}/updateScore/`, {
          params: {
            insertedId: location.state.insertedId,
            playerName: location.state.playerName,
            score: location.state.score.toString()
          }
        })
        .then(
          () => {},
          (err) => {
            console.log(err);
            navigate('/error', {
              state: { errorMessage: 'Unable to update user score' }
            });
          }
        );

      const gameId = location.state.gameId;
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/getGameById?gameId=${gameId}`
        )
        .then(
          (res) => {
            if (res?.data?.redirectUrl) {
              setRedirectUrl(res.data.redirectUrl);
            }
          },
          (err) => {
            console.log(err);
            navigate('/error', {
              state: { errorMessage: 'Unable to retrieve game data' }
            });
          }
        );
    }
  }, [location.state, navigate]);

  const routeChange = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      navigate('/Error', {
        state: { errorMessage: 'Unable to update user score' }
      });
    }
  };

  return (
    <GameContainerComponent>
      <ImageOverlay src={marketClosed} />
      {(location?.state?.score || location?.state?.score === 0) && (
        <MessagesSection>
          <Message>THANK YOU FOR PLAYING</Message>
          <Message>
            <b>YOUR FINAL SCORE IS</b>
          </Message>
          <Score>{location.state.score}</Score>
          <Message>CHECK THE LEADERBOARD TO SEE HOW YOU DID</Message>
          <Actions>
            <SecondaryButton
              buttonAction={routeChange}
              content="PLAY AGAIN"
              size="massive"
            />
          </Actions>
        </MessagesSection>
      )}
    </GameContainerComponent>
  );
};

export default ThanksPage;
