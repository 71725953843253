/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import {
  GridColumn,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader
} from 'semantic-ui-react';
import styled from 'styled-components';

import alertHeader from '../img/alert-header.png';

const StyledNewsAlert = styled.div`
  width: 100px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.font};
  text-align: left;
  padding-left: 20px;
`;

const AlertHeaderBackground = styled(GridColumn)`
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  padding: 5px 0 0 20px !important;
  border-top-left-radius: 15px;
  min-width: 300px;
  min-height: 65px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AlertModal = styled(Modal)`
  // background-color: ${(props) => props.theme.colors.backgroundGrey};
  background-color: green;
`;

const AlertModalComponent = ({ alertTitle, timer, content, actions }) => {
  return (
    <AlertModal open={true}>
      <ModalHeader>
        <Header>
          <AlertHeaderBackground backgroundimage={alertHeader} floated="left">
            <StyledNewsAlert>{alertTitle}</StyledNewsAlert>
          </AlertHeaderBackground>
          {timer}
        </Header>
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ModalActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 20px 10px 10px'
        }}
      >
        {actions.map((action, index) => (
          <div key={index}>{action}</div>
        ))}
      </ModalActions>
    </AlertModal>
  );
};

export default AlertModalComponent;
