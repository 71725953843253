/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import AlertModalComponent from './AlertModalComponent';
import { DismissButton, SecondaryButton } from './ButtonComponents';

const EventContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 15em;
  font-size: 22px;
  margin-left: 50px;
  margin-right: 50px;
  color: ${(props) => props.theme.colors.font};
`;

const NewsAlertResultComponent = ({
  eventResult,
  setShowEventResult,
  incrementCurRound
}) => {
  const [timePassed, setTimePassed] = useState(
    parseInt(process.env.REACT_APP_EVENT_RESULT_DURATION, 10)
  );

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setTimePassed((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          }

          return prevTime;
        }),
      1000
    );
    return () => {
      clearInterval(intervalId); // Clear the interval to avoid memory leaks
    };
  }, []);

  useEffect(() => {
    if (timePassed <= 0) {
      incrementCurRound();
      setShowEventResult(false);
    }
  }, [timePassed]);

  const content = <EventContents>{eventResult}</EventContents>;
  const actions = [
    <DismissButton
      content={'DISMISS'}
      buttonAction={() => setTimePassed(0)}
      key="dismiss"
    />,
    <SecondaryButton
      positive
      content={`Trading starting in ${timePassed} seconds`}
      disabled
      key="info"
    />
  ];

  return (
    <AlertModalComponent
      alertTitle="EVENT RESULT"
      content={content}
      actions={actions}
    />
  );
};

export default NewsAlertResultComponent;
