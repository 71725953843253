/* eslint-disable react/prop-types */
import { Container, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

const BackgroundContainer = styled(Container)`
  background-color: #232630;
  border: 1px solid #1c3255;
  border-radius: 15px;
  padding: 25px;
  width: 550px !important;
  height: 350px !important;

  @media (max-width: 1200px) {
    width: 500px !important;
    height: 300px !important;
  }

  @media (max-width: 1000px) {
    width: 350px !important;
    height: 300px !important;
  }

  @media (max-width: 700px) {
    width: 100px !important;
    height: 50px !important;
  }
`;

const Title = styled.div`
  font-size: 10pt;
  color: ${(props) => props.theme.colors.font};
`;

const TradingComponent = ({ title, children }) => {
  return (
    <BackgroundContainer>
      <Grid>
        <GridRow columns={1}>
          <GridColumn textAlign="left">
            <Title>{title}</Title>
          </GridColumn>
        </GridRow>
        <GridRow columns={1}>
          <GridColumn>{children}</GridColumn>
        </GridRow>
      </Grid>
    </BackgroundContainer>
  );
};

export default TradingComponent;
