/* eslint-disable react/prop-types */
import {
  Button,
  Container,
  Grid,
  GridColumn,
  GridRow
} from 'semantic-ui-react';
import styled from 'styled-components';

const BackgroundContainer = styled(Container)`
  padding-right: 20px;
  padding-bottom: 10px;
  width: 500px !important;

  @media (max-width: 1200px) {
    width: 400px !important;
  }

  @media (max-width: 1000px) {
    width: 350px !important;
  }

  @media (max-width: 700px) {
    width: 100px !important;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    color: ${(props) => props.theme.colors.font};
    font-size: 14pt;
    font-weight: 900;
    border-radius: 0.5em;
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    border: 1px solid ${(props) => props.theme.colors.font};
    padding: 10px 50px 10px 50px;
    width: 120px;

    @media (max-width: 1200px) {
      width: 100px;
      font-size: 12pt;
      padding: 10px 40px 10px 40px;
    }

    @media (max-width: 1000px) {
      width: 70px;
      font-size: 12pt;
      padding: 10px 40px 10px 40px;
    }
  }
`;

const Label = styled.div`
  font-size: 12pt;
  color: ${(props) => props.theme.colors.font};
`;

const TradingPositionComponent = ({ position }) => {
  return (
    <BackgroundContainer>
      <Grid>
        <GridRow verticalAlign="middle">
          <GridColumn textAlign="right" width={12}>
            <Label>POSITION</Label>
          </GridColumn>
          <GridColumn floated="right" textAlign="right" width={4}>
            <StyledButton>
              {position > 0 ? `+${position}` : position}
            </StyledButton>
          </GridColumn>
        </GridRow>
      </Grid>
    </BackgroundContainer>
  );
};
export default TradingPositionComponent;
