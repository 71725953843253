/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PrimaryButton } from '../components/ButtonComponents';
import ImageOverlay from '../components/ImageOverlay';
import introMain from '../img/intro-main.png';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30vh 0 50px 0;
`;

const IntroText = styled.div`
  color: ${(props) => props.theme.colors.font};
  font-size: 20px;
  text-align: center;
`;

const IntroPage = () => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [hasRequiredParams, setHasRequiredParams] = useState(false);
  const [curInstance, setCurInstance] = useState(0);
  const [gameId] = useState(searchParams.get('gameId'));
  const [userName] = useState(searchParams.get('userName'));
  const [userId] = useState(searchParams.get('userId'));

  useEffect(() => {
    if (gameId && userName && userId) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/getGameById?gameId=${gameId}`
        )
        .then(
          () => {
            setHasRequiredParams(true);
          },
          (err) => {
            console.log(err);
            navigate('/error', {
              state: { errorMessage: 'The Game ID provided is not valid' }
            });
          }
        );

      //to clear the querystring
      setSearchParams('');

      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/getRandomInstance/`)
        .then((res) => {
          setCurInstance(res.data.randomNumberInstance);
        })
        .catch((error) => {
          console.log(error);
          navigate('/error');
        });
    } else {
      navigate('/error', {
        state: { errorMessage: 'The URL querystring is invalid' }
      });
    }
  }, [setSearchParams, gameId, userName, userId, navigate]);

  const startGame = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/register/`, {
        params: {
          userName,
          gameId,
          userId
        }
      })
      .then((res) => {
        let mydata = res.data;
        const insertedId = mydata;
        let path = '/trading';
        navigate(path, {
          state: {
            insertedId: insertedId,
            playerName: userName,
            curInstance: curInstance,
            gameId: gameId
          }
        });
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', {
          state: { errorMessage: 'Failed to create user' }
        });
      });
  };

  return (
    <>
      {hasRequiredParams && (
        <>
          <ImageOverlay src={introMain} />
          <Column>
            <Messages>
              <IntroText>Find out if you have what it takes</IntroText>
              <IntroText>to be an Optiver trader</IntroText>
            </Messages>
            <PrimaryButton
              buttonAction={startGame}
              content="PLAY"
              size="massive"
            />
          </Column>
        </>
      )}
    </>
  );
};

export default IntroPage;
