/* eslint-disable react/prop-types */
import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, YAxis } from 'recharts';
import { Button, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled, { useTheme } from 'styled-components';

const StyledButton = styled(Button)`
  &&& {
    color: ${(props) => props.theme.colors.font};
    font-size: 14pt;
    font-weight: 900;
    border-radius: 0.5em;
    background-color: #232630;
    border: 1px solid #1c3255;
    width: 150px;

    @media (max-width: 1200px) {
      width: 90px;
      font-size: 10pt;
    }

    @media (max-width: 1000px) {
      width: 80px;
      font-size: 8pt;
    }
  }
`;

const StyledGridRow = styled(GridRow)`
  &&& {
    padding-top: 0;
    padding-bottom: 1em;
    margin-right: 20px;

    @media (max-width: 1200px) {
      margin-right: 10px;
    }

    @media (max-width: 1000px) {
      margin-right: 0px;
    }
  }
`;

const StockViewerComponent = ({ values }) => {
  const theme = useTheme();
  const size = useWindowSize();

  const stockValue = parseInt(values[values.length - 1].stock);
  const yAxisUpperBound = Math.round(stockValue + 15);
  const yAxisLowerBound = Math.round(stockValue - 15);

  const [chartSize, setChartSize] = useState([500, 200]);

  useEffect(() => {
    const w = size.width;

    if (w > 1200) {
      setChartSize([500, 200]);
      return;
    }

    if (w > 1000) {
      setChartSize([400, 150]);
      return;
    }

    if (w > 700) {
      setChartSize([300, 150]);
      return;
    }

    setChartSize([150, 100]);
  }, [size.width]);

  return (
    <Grid>
      <StyledGridRow columns={1}>
        <GridColumn width={5} floated="right">
          <StyledButton icon="dollar" content={stockValue} />
        </GridColumn>
      </StyledGridRow>
      <StyledGridRow columns={1}>
        <GridColumn width={6} textAlign="center">
          <AreaChart data={values} width={chartSize[0]} height={chartSize[1]}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#2862FF" stopOpacity={0.8} />
                <stop offset="80%" stopColor="#232630" stopOpacity={0.2} />
              </linearGradient>
            </defs>
            <YAxis
              orientation="right"
              tickCount="7"
              allowDecimals="false"
              allowDataOverflow="true"
              axisLine="false"
              tickLine="false"
              domain={[yAxisLowerBound, yAxisUpperBound]}
              tick={{
                fontSize: 10,
                fill: theme.colors.font
              }}
              width={20}
            />
            <CartesianGrid
              strokeDasharray="0"
              stroke="rgba(36, 66, 87)"
              vertical={false}
            />
            <Area
              type="monotone"
              dataKey="stock"
              stroke={theme.colors.font}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </GridColumn>
      </StyledGridRow>
    </Grid>
  );
};
export default StockViewerComponent;
