/* eslint-disable react/prop-types */
/* eslint-disable no-undef */

import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

const RecordLabel = styled.div`
  color: ${(props) =>
    props.ordertype === 'Buy'
      ? props.theme.colors.buy
      : props.theme.colors.sell};
`;

const RecordValue = styled(RecordLabel)`
  float: right;
`;

const RecordRow = styled(GridRow)`
  border-top: 1px solid #1c3255;
  font-weight: 900;
  font-size: 14px;

  @media (max-width: 1200px) {
    font-size: 10pt;
  }

  @media (max-width: 1000px) {
    font-size: 8pt;
  }
`;

const StockTradesListComponent = ({ recordsForDisplay }) => {
  const lastFourInReverse = recordsForDisplay.slice(-4).reverse();
  return (
    <Grid padded>
      <GridRow columns={1}>
        <GridColumn>
          {lastFourInReverse.map((item, index) => {
            return (
              <Grid key={`${item.id}_${index}`}>
                <RecordRow columns={2}>
                  <GridColumn width={4}>
                    <RecordLabel ordertype={item.order}>
                      {item.order.toUpperCase()} AT
                    </RecordLabel>
                  </GridColumn>
                  <GridColumn floated="right">
                    <RecordValue ordertype={item.order}>
                      {item.orderAddress}
                    </RecordValue>
                  </GridColumn>
                </RecordRow>
              </Grid>
            );
          })}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
export default StockTradesListComponent;
