import { useState } from 'react';
import styled from 'styled-components';

import {
  BuyButton,
  PrimaryButton,
  SecondaryButton,
  SellButton
} from './ButtonComponents';
import GameContainerComponent from './GameContainerComponent';
import ImageOverlay from './ImageOverlay';
import grid from '../img/grid.png';
import ins1 from '../img/ins1.png';
import ins2 from '../img/ins2.png';
import ins3 from '../img/ins3.png';
import ins4 from '../img/ins4.png';
import ins5 from '../img/ins5.png';
import ins6 from '../img/ins6.png';
import tradeMain from '../img/trade-main.png';

const TutorialContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const InstructionsLeft = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 100px 50px 50px;
  border-right: 1px solid #448a9d;
  min-width: 500px;

  @media (max-width: 1200px) {
    min-width: 400px;
    padding: 0 50px 50px 50px;
  }

  @media (max-width: 1000px) {
    min-width: 350px;
    padding: 0 50px 50px 50px;
  }
`;

const InstructionsRight = styled.div`
  flex: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(${grid});
  background-size: cover;
`;

const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Actions = styled.div`
  display: flex;
  justify-content: left;
  padding-left: 50px;
  width: 100%;

  @media (max-width: 1000px) {
    padding-left: 0;
  }
`;

const ActionsContainer = styled.div`
  margin-right: 20px;
`;

const LastPageActions = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  padding: 0 50px 50px 100px;
`;

const SectionText = styled.div`
  color: ${(props) => props.theme.colors.font};
  font-size: 20px;
  padding-bottom: 15px;
  letter-spacing: 1px;
  font-weight: 300;
`;

const InstructionsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0 10px 0;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 1px solid #143459;
`;

const InstructionsMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 14px;
  padding-top: 25px;
  color: ${(props) => props.theme.colors.primary};

  & b {
    font-weight: 900;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 400px;
`;

const tutorialSections = [
  {
    content: (
      <InstructionsMessages>
        <SectionText>
          Traders use vast information to make informed decisions at speed.
        </SectionText>
        <SectionText>
          In this game, that information is streamlined into a simple dashboard
          with <b>sliders</b> and <b>gauges</b> to guide your trades.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins1
  },
  {
    content: (
      <InstructionsMessages>
        <SectionText>
          Your mission is to react to market signals to maximise your profit.
        </SectionText>
        <SectionText>Playing is simple.</SectionText>
        <SectionText>
          Click <BuyButton size="mini" />
          to <b>buy</b>.
        </SectionText>
        <SectionText>
          Click <SellButton size="mini" />
          to <b>sell</b>.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins2
  },
  {
    content: (
      <InstructionsMessages>
        <SectionText>
          You can buy or sell as frequently as you like, but you are only
          allowed to own at most <b>5 lots of the stock</b>.
        </SectionText>
        <SectionText>
          Likewise, you are limited to being <b>short 5 lots</b>.
        </SectionText>
        <SectionText>
          Therefore, your position at any time will be an integer from -5 to +5.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins3
  },
  {
    section: 4,
    content: (
      <InstructionsMessages>
        <SectionText>
          A <b>positive</b> position profits when the stock <b>goes up</b>.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins4
  },
  {
    content: (
      <InstructionsMessages>
        <SectionText>
          A <b>negative</b> position profits when the stock <b>goes down</b>.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins5
  },
  {
    content: (
      <InstructionsMessages>
        <SectionText>
          Pay attention to <b>news alerts</b> containing information about an
          upcoming event.
        </SectionText>
        <SectionText>
          When one appears, the market will freeze, and a countdown begins. Use
          this time to read the event and decide on a position.
        </SectionText>
        <SectionText>
          When the time is up, the market resumes, and the outcome is revealed.
        </SectionText>
      </InstructionsMessages>
    ),
    image: ins6
  }
];

// eslint-disable-next-line react/prop-types
const TutorialComponent = ({ setTutorialComplete }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // For last section
  if (currentIndex === tutorialSections.length) {
    return (
      <GameContainerComponent>
        <ImageOverlay src={tradeMain} alt="Overlay Image" />
        <Column>
          <PrimaryButton
            buttonAction={setTutorialComplete}
            content="GO"
            size="massive"
          />
        </Column>
        <LastPageActions>
          <SecondaryButton
            buttonAction={() => setCurrentIndex(currentIndex - 1)}
            content="PREVIOUS"
            size="large"
          />
        </LastPageActions>
      </GameContainerComponent>
    );
  }

  const currentSection = tutorialSections[currentIndex];

  return (
    <GameContainerComponent>
      <TutorialContainer>
        <InstructionsLeft>
          <Instructions>
            <InstructionsTitle>
              <div>GAME INSTRUCTIONS</div>
              <div>
                <span style={{ color: 'white' }}>{currentIndex + 1}</span>/
                {tutorialSections.length}
              </div>
            </InstructionsTitle>
            <InstructionsMessages>
              {currentSection.content}
            </InstructionsMessages>
          </Instructions>
          <Actions>
            <ActionsContainer>
              {currentIndex > 0 ? (
                <SecondaryButton
                  buttonAction={() => setCurrentIndex(currentIndex - 1)}
                  content="PREVIOUS"
                  size="large"
                />
              ) : (
                <div style={{ width: '124px' }}></div>
              )}
            </ActionsContainer>
            <SecondaryButton
              buttonAction={() => setCurrentIndex(currentIndex + 1)}
              content="NEXT"
              size="large"
            />
          </Actions>
        </InstructionsLeft>
        <InstructionsRight>
          <img
            src={currentSection.image}
            style={{ zIndex: 1, width: '100%' }}
          />
        </InstructionsRight>
      </TutorialContainer>
    </GameContainerComponent>
  );
};

export default TutorialComponent;
