import AdminComponent from '../components/AdminComponent';
import AdminContainerComponent from '../components/AdminContainerComponent';

const AdminPage = () => {
  return (
    <AdminContainerComponent pageHeader={'Manage Games & Leaderboards'}>
      <AdminComponent />
    </AdminContainerComponent>
  );
};

export default AdminPage;
