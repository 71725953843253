/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { copyIcon } from './../img/icons';
import GameAccordionItem from './GameAccordionItemComponent';

const ContentSectionColumn = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  margin-top: 24px;
`;

const LHeading = styled.div`
  color: ${(props) => props.theme.colors.font};
  font-size: 24px;
  line-height: 130%;
`;

const CreateGameContainer = styled.div`
  margin-top: 20px;
  border-radius: 4px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const SHeading = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${(props) => props.theme.colors.font};
`;

const InputContainer = styled.div`
  font-size: 16px;
  display: flex;
  flex: 1;
  margin-top: 8px;
  color: black;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  font-size: 16px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-weight: 400;
  width: 100%;
  line-height: 24px;
`;

const InputButton = styled.button`
  background-color: #213253;
  height: 50px;
  color: white;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  display: flex;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  margin-left: -2px;
`;

const CopyBox = styled.div`
  display: flex;
  height: 48px;
  padding: 8px;
  justify-content: flex-start;
  align-content: center;
  border-radius: 4px;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.font};
  align-items: center;
  font-size: 16px;
`;

const ActiveGamesHeading = styled.div`
  height: 52px;
  color: ${(props) => props.theme.colors.font};
  font-size: 24px;
  align-items: center;
  padding-left: 16px;
  display: flex;
  border-radius: 4px;
`;

const CopiedAlert = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.font};
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  left: ${(props) => props.leftLocation}px;
  top: ${(props) => props.topLocation - 40}px;
`;

const AdminComponent = () => {
  const [gamesList, setGamesList] = useState([]);
  const [isGameUpdated, setIsGameUpdated] = useState(false);
  const [newGameName, setNewGameName] = useState('');
  const [gameRedirectUrl, setGameRedirectUrl] = useState('');
  const [gameNameValidationError, setGameNameValidationError] = useState('');
  const [gameRedirectUrlValidationError, setGameRedirectUrlValidationError] =
    useState('');
  const [generatedGameId, setGeneratedGameId] = useState('');
  const [usersList, setUsersList] = useState();
  const [copiedAlertDetails, setCopiedAlertDetails] = useState({
    showCopiedAlert: false,
    leftLocation: 0,
    topLocation: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getGames/`)
      .then((res) => {
        setGamesList(res.data);
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', { state: { errorMessage: 'Unable to get games' } });
      });

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getAllUsers/`)
      .then((res) => {
        setUsersList(res.data);
      })
      .catch((error) => {
        console.log(error);
        navigate('/error', { state: { errorMessage: 'Unable to get users' } });
      });

    setIsGameUpdated(false);
  }, [isGameUpdated, setIsGameUpdated, navigate]);

  const onCopy = (e, value) => {
    console.log(e);
    if (e.pageX && e.pageY) {
      setCopiedAlertDetails({
        showCopiedAlert: true,
        leftLocation: e.pageX,
        topLocation: e.pageY - 10
      });
      navigator.clipboard.writeText(value);

      const timeOut = () => new Promise((resolve) => setTimeout(resolve, 1200));

      timeOut().then(() => setCopiedAlertDetails({ showCopiedAlert: false }));
    }
  };

  const onGenerateGame = () => {
    let convertedUrl = '';

    try {
      convertedUrl = new URL(gameRedirectUrl);
    } catch {
      /* empty */
    }

    if (newGameName === '') {
      setGameNameValidationError('please enter a valid game name');
    }
    if (gameRedirectUrl === '' || convertedUrl === '') {
      setGameRedirectUrlValidationError('please enter a valid URL');
    }
    if (gameRedirectUrl !== '' && newGameName !== '' && convertedUrl !== '') {
      setGameNameValidationError('');
      setGameRedirectUrlValidationError('');
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/creategame/`, {
          params: { gameName: newGameName, redirectUrl: gameRedirectUrl }
        })
        .then((res) => {
          if (res.data === 'Game name is already in use') {
            setGameNameValidationError('Game name is already in use');
          } else {
            setIsGameUpdated(true);
            setGeneratedGameId(res.data);
            setNewGameName('');
            setGameRedirectUrl('');
          }
        })
        .catch((error) => {
          console.log(error);
          navigate('/error', {
            state: { errorMessage: 'Unable to create new game' }
          });
        });
    }
  };

  return (
    <>
      <ContentSectionColumn>
        <LHeading>Create a new game</LHeading>
        <CreateGameContainer>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 20,
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 2,
                minWidth: 200
              }}
            >
              <SHeading>Type in a game nickname</SHeading>

              <InputContainer>
                <Input
                  name="gameNameInput"
                  placeholder="Example: Moola"
                  value={newGameName}
                  onChange={(e) => {
                    setGameNameValidationError('');
                    setNewGameName(e.target.value);
                  }}
                />
              </InputContainer>
              <div
                style={{
                  color: 'red',
                  height: 20,
                  marginBottom: -20
                }}
              >
                {gameNameValidationError}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 2,
                minWidth: 200
              }}
            >
              <SHeading>Type in a game redirect URL</SHeading>
              <InputContainer>
                <Input
                  name="gameRedirectUrlInput"
                  placeholder="http://optiver-1.hubspotpagebuilder.com/optiver-trading-game-template"
                  value={gameRedirectUrl}
                  onChange={(e) => {
                    setGameRedirectUrl('');
                    setGameRedirectUrl(e.target.value);
                  }}
                />
              </InputContainer>
              <div
                style={{
                  color: 'red',
                  height: 20,
                  marginBottom: -20
                }}
              >
                {gameRedirectUrlValidationError}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                minWidth: 200,
                marginTop: 20
              }}
            >
              <InputButton onClick={onGenerateGame}>
                Generate game Id
              </InputButton>
            </div>
          </div>

          <div>
            <SHeading>Copy game Id to share</SHeading>
            <CopyBox
              style={{
                justifyContent: 'space-between',
                backgroundColor: '#F6F5F5',
                color: 'black',
                width: 280
              }}
              onClick={(e) => onCopy(e, generatedGameId)}
            >
              <div>{generatedGameId}</div>
              <img src={copyIcon} alt="copy game id" />
            </CopyBox>
          </div>
        </CreateGameContainer>
      </ContentSectionColumn>
      <ContentSectionColumn>
        <ActiveGamesHeading>Active Games</ActiveGamesHeading>
      </ContentSectionColumn>
      {gamesList.length > 0 &&
        gamesList.map((game) => {
          if (!game.archived)
            return (
              <GameAccordionItem
                key={game._id}
                game={game}
                users={usersList}
                onCopy={(e, a) => onCopy(e, a)}
                setIsGameUpdated={setIsGameUpdated}
                isGameUpdated={isGameUpdated}
              />
            );
          else return <div key={game._id}></div>;
        })}
      <ContentSectionColumn style={{ marginTop: 4 }}>
        <ActiveGamesHeading>Deactivated Games</ActiveGamesHeading>
      </ContentSectionColumn>
      <div style={{ marginBottom: 50 }}>
        {gamesList.length > 0 &&
          gamesList.map((game) => {
            if (game.archived)
              return (
                <GameAccordionItem
                  key={game._id}
                  game={game}
                  users={usersList}
                  onCopy={(e, a) => onCopy(e, a)}
                  setIsGameUpdated={setIsGameUpdated}
                  isGameUpdated={isGameUpdated}
                  navigate={navigate}
                />
              );
            else return <div key={game._id}></div>;
          })}
      </div>
      {copiedAlertDetails.showCopiedAlert && (
        <CopiedAlert {...copiedAlertDetails}>Copied!</CopiedAlert>
      )}
    </>
  );
};

export default AdminComponent;
