import { useState } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '../components/ButtonComponents';
import GameContainerComponent from '../components/GameContainerComponent';
import useBasicAuth from '../hooks/useBasicAuth';

const FormField = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.font};
  height: 100%;
`;

// eslint-disable-next-line react/prop-types
const LoginPage = () => {
  const { login } = useBasicAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    login(username, password);
  };

  return (
    <GameContainerComponent>
      <StyledLogin>
        <h2>Login</h2>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <form onSubmit={handleLogin}>
          <FormField>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormField>
          <FormField>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormField>
          <FormField>
            <PrimaryButton type="submit" content="Login" />
          </FormField>
        </form>
      </StyledLogin>
    </GameContainerComponent>
  );
};

export default LoginPage;
