import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorComponent from './ErrorComponent';
import GameContainerComponent from './GameContainerComponent';
import useAmplifyAuth from '../hooks/useAmplifyAuth';

// eslint-disable-next-line react/prop-types
export const ProtectedRouteAmplify = ({ children }) => {
  const { authStatus } = useAmplifyAuth();

  if (authStatus === 'configuring') {
    return <GameContainerComponent>Loading...</GameContainerComponent>;
  }

  if (authStatus === 'authenticated') {
    return <Authenticator hideSignUp>{children}</Authenticator>;
  }

  return Auth.federatedSignIn({
    // eslint-disable-next-line no-undef
    provider: process.env.REACT_APP_FEDERATED_IDENTITY_NAME
  });
};

// eslint-disable-next-line react/prop-types
export const ProtectedRouteBasic = ({ redirect, children }) => {
  const authStatus = localStorage.getItem('user');
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStatus) {
      return navigate('/login', { state: { redirect } });
    }
  }, [authStatus]);

  if (authStatus === 'unauthenticated') {
    return (
      <ErrorComponent message="Access Denied: You are not authorized to view this content." />
    );
  }

  if (authStatus === 'authenticated') {
    return children;
  }
};
